<template>
  <div>
    <v-card>
      <v-card-title
        class="justify-center"
        style="background-color: #006a50; color: aliceblue"
        >YTD Sales Report
      </v-card-title>
      <v-row>
        <v-col xl="12" md="12">
          <v-card>
            <v-data-table
              :headers="headersDays"
              :items="itemsDays"
              sort-by="name"
              hide-default-footer
              hide-default-header
              class="elevation-1"
            >
            <template slot="no-data">
   يتم تحميل البيانات
  </template>
              <template v-slot:header="{ props: { headers } }">
                <thead>
                  <tr>
                    <th v-for="(h, i) in headers" :class="h.class" :key="i">
                      <span>{{ h.text }}</span>
                    </th>
                  </tr>
                </thead>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col xl="12" md="12">
          <v-card>
            <v-data-table
              :headers="headersYTD"
              :items="itemsYTD"
              sort-by="name"
              hide-default-footer
              hide-default-header
              class="elevation-1"
            >
            <template slot="no-data">
   يتم تحميل البيانات
  </template>
              <template v-slot:header="{ props: { headers } }">
                <thead>
                  <tr>
                    <th v-for="(h, i) in headers" :class="h.class" :key="i">
                      <span>{{ h.text }}</span>
                    </th>
                  </tr>
                </thead>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-divider></v-divider>
    <!-- </v-col>
    <v-col xl="6" md="8"> -->
    <v-card>
      <v-data-table
        :headers="headersYTDAvg"
        :items="itemsYTDAvg"
        sort-by="name"
        hide-default-footer
        hide-default-header
        class="elevation-1"
      >
      <template slot="no-data">
   يتم تحميل البيانات
  </template>
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th v-for="(h, i) in headers" :class="h.class" :key="i">
                <span>{{ h.text }}</span>
              </th>
            </tr>
          </thead>
        </template>
      </v-data-table>
    </v-card>
    <!-- </v-col>
</v-row> -->

    <v-divider></v-divider>
    <v-card class="mx-auto">
      <v-card-title
        class="justify-center"
        style="background-color: #015684; color: aliceblue"
      >
        Products Actual Contribuation
      </v-card-title>

      <v-container>
        <v-row>
          <v-row>
            <v-col cols="10" sm="10" md="10">
              <apexchart
                :series="seriesBAGOPM"
                height="300"
                :options="optionsBAGOPM"
              ></apexchart>
            </v-col>
          </v-row>

          <v-col cols="12" sm="12" md="12">
            <apexchart
              :series="seriesProductActual"
              height="400"
              :options="optionsProductActual"
            ></apexchart>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-divider></v-divider>
    <v-card class="mx-auto justify-center">
      <v-card-title
        class="justify-center"
        style="background-color: #015684; color: aliceblue"
      >
        Products Anual Target
      </v-card-title>
      
      <v-container class="justify-center">
        <v-row>
          <v-col cols="10" sm="10" md="10">
            <apexchart
              :series="seriesAnnualTarget"
              :options="optionsAnnualTarget"
            ></apexchart>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="mx-auto">
      <v-card-title
        class="justify-center"
        style="background-color: #015684; color: aliceblue"
      >
        Sales By Factory
      </v-card-title>

      =

      <v-data-table
        :headers="headerSalesFactory"
        :items="ItemSalesFactory"
        sort-by="month"
        class="elevation-1 mt-3"
      >
      </v-data-table>
    </v-card>
    <v-divider></v-divider>
    <v-card class="mx-auto">
      <v-card-title
        class="justify-center"
        style="background-color: #015684; color: aliceblue"
        >Monthly Sales
      </v-card-title>

      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              :headers="headersMonth"
              :items="ItemheadersMonth"
              class="elevation-1 mt-3"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-divider></v-divider>
    <v-card class="mx-auto">
      <v-card-title
        class="justify-center"
        style="background-color: #015684; color: aliceblue"
        >KPI</v-card-title
      >

      <v-container>
       
        <v-row>
          <v-col cols="6" sm="6" md="6">
            <apexchart :series="seriesKpi" :options="optionsKpi"></apexchart>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <!-- {{seriesKpiActivity}} -->
            <apexchart
              :series="seriesKpiActivity"
              :options="optionsKpiActivity"
            ></apexchart>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-divider></v-divider>
    <v-card class="mx-auto">
      <v-container>
        <v-row>
          <v-col cols="6" sm="6" md="6">
            <v-card>
              <v-card-title style="background-color: #015684; color: aliceblue"
                >Market Share</v-card-title
              >

              <apexchart
                :series="seriesMarketShareData"
                :options="optionsMarketShareData"
              >
              </apexchart>
            </v-card>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-card class="mx-auto">
              <v-card-title style="background-color: #015684; color: aliceblue"
                >Price Averge</v-card-title
              >
         
              <apexchart
                :series="seriesPriceAvg"
                :options="optionsPriceAvg"
              ></apexchart>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
// import AreaDetails1 from './area-details.vue';
// import areadetails from "./areadetails.vue";
import axios from "axios";

export default {
  name: "market-segmentation",

  components: {
    // areadetails
  },

  data() {
    return {
      series2: [],
      dataKpiActivityActual: [],
      monthsName: [
        {
          month: "Jan",
        },
        { month: "Feb" },
        { month: "Mar" },
        { month: "Apr" },
        { month: "May" },
        { month: "Jun" },
        { month: "Jul" },
        { month: "Aug" },
        { month: "Sep" },
        { month: "Oct" },
        { month: "Nov" },
        { month: "Dec" },
      ],
      dataKpi: [],
      months: [
        { name: "كانون الثاني", num: "0" },
        { name: "شباط", num: "1" },
        { name: "آذار", num: "2" },
        { name: "نيسان", num: "3" },
        { name: "أيار", num: "4" },
        { name: "حزيران", num: "5" },
        { name: "تموز", num: "6" },
        { name: "آب", num: "7" },
        { name: "أيلول", num: "8" },
        { name: "تشرين الأول", num: "9" },
        { name: "تشرين الثاني", num: "10" },
        { name: "كانون الأول", num: "11" },
      ],
      thisMonth: "",
      data: [],
      dataPriceAvg: [],
      dataPriceAvgAnnual: [],
      optionsMarketShareData: {
        chart: {
          redrawOnParentResize: true,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
          type: "line",
        },

        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          type: "date",
        },
      },
      optionsPriceAvg: {
        chart: {
          type: "bar",
          height: 2000,
        },

        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            // colors: ["#64699a","#f6832d"]
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
      },
      month: "",
      day: "",
      QuantityMonths: [],
      monthsNameArabic: [
        { name: "كانون الثاني", num: "0" },
        { name: "شباط", num: "1" },
        { name: "آذار", num: "2" },
        { name: "نيسان", num: "3" },
        { name: "أيار", num: "4" },
        { name: "حزيران", num: "5" },
        { name: "تموز", num: "6" },
        { name: "آب", num: "7" },
        { name: "أيلول", num: "8" },
        { name: "تشرين الأول", num: "9" },
        { name: "تشرين الثاني", num: "10" },
        { name: "كانون الأول", num: "11" },
      ],
      optionsKpi: {
        chart: {
          type: "bar",
          height: 2000,
        },

        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#64699a"],
          },
        },
        xaxis: {
          categories: [],
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        yaxis: {
          reversed: true,
          axisTicks: {
            show: true,
          },
        },
      },
      optionsKpiActivity: {
        chart: {
          type: "bar",
          height: 2000,
        },

        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#64699a"],
          },
        },
        xaxis: {
          categories: [],
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        yaxis: {
          reversed: true,
          axisTicks: {
            show: true,
          },
        },
      },
      optionsProductActual: {
        colors: ["#64699a", "#d19501", "#991533", "#008b65"],
        chart: {
       
          type: 'bar',
              height: 350,
              stacked: true,
              toolbar: {
                show: true
              },
              zoom: {
                enabled: true
              }
        },


        plotOptions: {
          bar: {
            columnWidth: "25%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -30,
        },
        yaxis: [
          {
            title: {
              text: "Counts",
            },
          },
          {
            max: 100,
            min: 0,
            opposite: true,
            title: {
              text: "(%) Complete",
            },
          },
        ],
        xaxis: {
          categories: [["SRC BULK"], ["SRC BAG"], ["OPM BAG"], ["OPM BULK"]],
          // labels: {
          //   style: {
          //     colors: ["red"],
          //     fontSize: "20px",
          //    wordBreak: 'break-all'
          //   },
          // },
        },
      },
      PlanAvg: [],
      ActualAvg: [],
      AnnualDaysOff: [],
      year: "",
      optionsBAGOPM: {
        colors: ["#bebebe", "#fdd0cb"],
        chart: {
          width: 380,
          height: 400,
          type: "pie",
        },
        labels: ["Bag", "Bulk"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      optionsAnnualTarget: {
        chart: {
          type: "bar",
          height: 2000,
          stackType: "100%",
        },

        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            // colors: ["#64699a","#f6832d"]
          },
        },
        xaxis: {
          categories: ["SRC BULK", "SRC BAG", "OPM BAG", "OPM BULK"],
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
      },

      AnnualRegion: [],
      dataKpiAnnual: [],
      selected: [],
      desserts: [],
      companynames: [],
      AnnualProduct: [],
      DataProductPlan: [],
      itemsYTD: [],
      headersYTD: [
        {
          text: "YTD Plan",

          value: "ytdplan",
          class: "my-header-style",
        },
        { text: "YTD Actual", value: "ytdactual", class: "my-header-style" },
        { text: "Annual Plan", value: "annualplan", class: "my-header-style" },
        {
          text: "Annual Remaning",
          value: "annualremanining",
          class: "my-header-style",
        },
      ],
      itemsDays: [],
      headersDays: [
        {
          text: "Sales Days Count",

          value: "numberDayUntillNow",
          class: "avg-style",
        },
        { text: "Remaning Days", value: "remaningdays", class: "avg-style" },
        { text: "Date", value: "today", class: "avg-style" },
        { text: "Ending date", value: "lastday", class: "avg-style" },
      ],
      itemsYTDAvg: [],
      headersYTDAvg: [
        {
          text: "YTD Actual Avg",
          align: "start",
          filterable: false,
          value: "ytdActualAvg",
          class: "avg-style",
        },
        { text: "Remaning Avg", value: "ramaningAvg", class: "avg-style" },
        {
          text: "YOY Avg Comparison",
          value: "yoyAvgComparison",
          class: "avg-style",
        },
        { text: "YTD Growth", value: "ytdGrowth", class: "avg-style" },
        {
          text: "YTD Achievement",
          value: "ytdAchievement",
          class: "avg-style",
        },
      ],
      ItemSalesFactory: [],
      annualPlan: 0,
      numberDayUntillNow: 0,
      headerSalesFactory: [
        {
          text: "الشهر",
          align: "start",
          filterable: false,
          value: "month",
        },
        { text: "Sales by Old Factory", value: "salesbyoldfactory" },
        { text: "Old Factory Avg", value: "oldfactoryavg" },
        { text: "Sales by New Factory", value: "salesbynewfactory" },
        { text: "New Factory Avg", value: "newfactoryacg" },
        { text: "Total", value: "total" },
        { text: "Total Avg", value: "totalavg" },
      ],
      ItemheadersMonth: [],
      headersMonth: [
        {
          text: "الشهر",
          align: "start",
          filterable: false,
          value: "month",
        },
        { text: "Sales Plan", value: "salesplan" },
        { text: "MTD Actual", value: "mtdactual" },
        { text: "Plan Avg", value: "planavg" },
        { text: "Actual Avg", value: "actualavg" },
        { text: " Avg Diff", value: "avgdiff" },
      ],

      total: 0,
      cement: [],
      RemaningDaysToFinishYear: "",
      numberDaysOff: "",
      AnnualMonths: [],
      dataOPM: 0,
      dataBAG: 0,
      series: [],
      seriesBAGOPM: [],
      DataProductActual: [],
      MarketShareData: [],
      dataKpiActivity: [],
      AnnualActivity: [],
      RemaningDays: 0,
      RemaningDaysOff: 0,
      annualremanining: 0,
    };
  },

  computed: {
    seriesKpiActivity() {
      let seriesKpiActivity = [
        {
          name: "Actual",
          type: "column",
          color: "#f6832d",
          data: this.dataKpiActivityActual,
        },
        {
          name: "Plan",
          type: "column",
          color: "#015686",
          data: this.dataKpiActivity,
        },
      ];
      return seriesKpiActivity;
    },
    seriesKpi() {
      let seriesKpi = [
        {
          name: "Actual",
          type: "column",
          color: "#f6832d",
          data: this.dataKpi,
        },
        {
          name: "Plan",
          type: "column",
          color: "#015686",
          data: this.dataKpiAnnual,
        },
      ];
      return seriesKpi;
    },
    seriesPriceAvg() {
      let seriesPriceAvg = [
        {
          name: "Actual",
          type: "column",
          color: "#f6832d",
          data: this.dataPriceAvg,
        },
        {
          name: "Plan",
          type: "column",
          color: "#015686",
          data: this.dataPriceAvgAnnual,
        },
      ];
      return seriesPriceAvg;
    },
    seriesAnnualTarget() {
      let seriesAnnualTarget = [
        {
          name: "Actual",
          type: "column",
          color: "#f6832d",
          data: this.DataProductActual,
        },
        {
          name: "Plan",
          type: "column",
          color: "#015686",
          data: this.DataProductPlan,
        },
      ];

      return seriesAnnualTarget;
    },
    series3() {
      let series3 = [
        {
          name: "Net Profit",
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
        },
        {
          name: "Revenue",
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
        },
      ];
      return series3;
    },
    seriesProductActual() {
      let series = [
        {
          data: this.DataProductActual,
        },
      ];
      return series;
    },

    seriesMarketShareData() {
      let MarketShare = [
        {
          name: "yamama sales",
          type: "column",

          data: this.MarketShareData,
        },
      ];
      return MarketShare;
    },
  },

  created() {
    this.getDate();
    this.getTotalSalesPerProduct();

    this.getPlanByYear();
    this.ketKpiActivity();
    // this.getYTDActual()
    this.getSalesByFactory();

    this.getKpi();
    this.getPriceAvg();
  },

  methods: {
    getSalesByFactory() {
      let oldFactory = [];
      let newFactory = [];

      axios({
        method: "get",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: false,
        url: `https://salestools.yamamacement.com/api/getreportService/getAvgMonthlySalesPerFactory?fromDate=1/1/${this.year}&toDate=31/12/${this.year}`,
      }).then((response) => {
        // let salesbynewfactory = [];

        response.data.responseData.forEach((el) => {
          if (el.factory.indexOf("0001") > -1) {
            oldFactory.push({
              salesbyoldfactory: el.quantity,
              oldfactoryavg: el.avgQuantity,
            });
          } else {
            newFactory.push({
              salesbynewfactory: el.quantity,
              newfactoryacg: el.avgQuantity,
            });
          }
        });

        // }
        newFactory = newFactory.map((item, i) =>
          Object.assign({}, item, this.monthsName[i])
        );

        //  let maxLength = Math.max(newFactory.length, oldFactory.length);

        // let oldAndNewInOneRow = [];
        // for (let i = 0; i < maxLength; i++) {
        //   oldAndNewInOneRow.push(newFactory.shift());
        //   oldAndNewInOneRow.push(oldFactory.shift());
        // }
        let Totalitems = [];
        var items = newFactory.map((item, i) =>
          Object.assign({}, item, oldFactory[i])
        );
        items.forEach((el) => {
          Totalitems.push({
            total: el.salesbyoldfactory + el.salesbynewfactory,
            totalavg: (el.newfactoryacg + el.oldfactoryavg) / 2,
          });
        });
        Totalitems.forEach((el) => {
          this.MarketShareData.push(el.total);
        });

    
        this.QuantityMonths = Totalitems.map((item, i) =>
          Object.assign({}, item, this.monthsNameArabic[i])
        );
        this.ItemSalesFactory = items.map((item, i) =>
          Object.assign({}, item, Totalitems[i])
        );

        this.getMonths();
        this.getMonthlySales();
      });
    },
    getTotalSalesPerProductAnnual() {
      let OPMBAG = 0;
      let SRCBAG = 0;
      let SRCBULK = 0;
      let OPMBULK = 0;

      this.AnnualProduct.forEach((el) => {
        if (el.productNumber.indexOf("OPM6") > -1) {
          OPMBAG += el.target;
        } else if (el.productNumber.indexOf("OPM5") > -1) {
          OPMBULK += el.target;
        } else {
          SRCBULK += el.target;
        }
      });
      this.DataProductPlan.push(SRCBULK);
      this.DataProductPlan.push(SRCBAG);
      this.DataProductPlan.push(OPMBAG);

      this.DataProductPlan.push(OPMBULK);
    },
    getPriceAvg() {
      this.ApiService.get(
        `https://salestools.yamamacement.com/api/getreportService/getPriceAverage?fromDate=1/1/${this.year}&toDate=31/12/${this.year}`
      ).then((res) => {
  
        res.data.responseData.forEach((el) => {
          this.dataPriceAvg.push(el.amavgPriceount);
        });

        this.AnnualMonths.forEach((el) => {
          this.dataPriceAvgAnnual.push(el.target);
        });
      });
    },
    getMonthlySales() {
      let salesPlan = [];
      this.AnnualMonths.forEach((el) => {
        salesPlan.push({ salesplan: el.target });
      });
      let mtdActual = [];
      this.ItemSalesFactory.forEach((el) => {
        mtdActual.push({ mtdactual: el.total });
      });

      let item = mtdActual.map((item, i) =>
        Object.assign({}, item, salesPlan[i])
      );
      item = item.map((item, i) => Object.assign({}, item, this.monthsName[i]));
      let itemActual = item.map((item, i) =>
        Object.assign({}, item, this.PlanAvg[i])
      );
      let Diff = [];

      this.ItemheadersMonth = itemActual.map((item, i) =>
        Object.assign({}, item, this.ActualAvg[i])
      );
      this.ItemheadersMonth.forEach((el) => {
        Diff.push({
          avgdiff: el.actualavg - el.planavg,
        });
      });
      this.ItemheadersMonth = this.ItemheadersMonth.map((item, i) =>
        Object.assign({}, item, Diff[i])
      );
    },
    getKpi() {
      this.ApiService.get(
        `https://salestools.yamamacement.com/api/getreportService/getKpi?fromDate=1/1/${this.year}&toDate=${this.month}/${this.day}/${this.year}`
      ).then((res) => {
      
        res.data.responseData.forEach((el) => {
          this.optionsKpi.xaxis.categories.push(el.city);
          this.dataKpi.push(el.quantity);
        });

        this.AnnualActivity.forEach((el) => {
          this.dataKpiActivity.push(el.target);
        });
        this.AnnualRegion.forEach((el) => {
          this.optionsKpi.xaxis.categories.push(el.name);
          this.dataKpiAnnual.push(el.target);
        });
      });
    },
    ketKpiActivity() {
      let ListWithCustomer = [];
      this.ApiService.get("Activity/ListWithCustomers").then((res) => {
       
        ListWithCustomer = res.data.responseData;

        ListWithCustomer.forEach((el) => {
          this.optionsKpiActivity.xaxis.categories.push(el.name);
        });
      });
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS"
       

        },
        withCredentials: false,
        url: `https://salestools.yamamacement.com/api/getreportService/getActualSalesForEachCustomer?fromDate=1/1/${this.year}&toDate=${this.month}/${this.day}/${this.year}`,
      }).then((res) => {
      
        let customerId = [];
        let CustomerNum = res.data.responseData;
        let Customer = [];
        // let quantity=0

        ListWithCustomer.forEach((el) => {
          el.customers.forEach((ele) => {
            customerId.push(ele);
          });
        });

        CustomerNum.forEach((el) => {
          customerId.forEach((ele) => {
            if (parseInt(el.customerNumber) == ele) {
              Customer.push(el);
            }
          });
        });
        let restData = [];

        ListWithCustomer.forEach((el) => {
          Customer.forEach((ele) => {
            el.customers.forEach((c) => {
              if (c == ele.customerNumber) {
                restData.push({
                  name: el.name,
                  quantity: ele.sumQuantity,
                });
              }
            });
          });
        });
        // let datWithName=[]
        const obj = restData.reduce((a, curr) => {
          if (!a[curr.name]) {
            a[curr.name] = curr;
          } else a[curr.name].quantity = a[curr.name].quantity + curr.quantity;
          return a;
        }, {});
        const arr = Object.values(obj);

        arr.forEach((el) => {
          this.dataKpiActivityActual.push(el.quantity);
        });
      });
    },
    getYTDAvg() {
      // this.getRemaningDays();

      axios({
        method: "get",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: false,
        url: `https://salestools.yamamacement.com/api/getreportService/getAcualSalesAverageGrouthAchievement?fromDate=1/1/${this.year}&toDate=${this.month}/${this.day}/${this.year}`,
      }).then((res) => {
      
        let ytdplan = 0;
        let QUANTITY = res.data[0].quantiyy;
        let AVG_QUANTITY = res.data[0].avgQuantity;
        let QUANTITY_LAS_YEAR = res.data[0].quantityLastYear;
        let ytdGrowth =
          ((QUANTITY - QUANTITY_LAS_YEAR) / QUANTITY_LAS_YEAR) * 100;
        let ytdAchivement = (QUANTITY * 100) / this.annualPlan;
        let AVG_QUANTITY_LAS_YEAR = res.data[0].avgquantityLastYear;
        let yoyAvgComparison =
          ((AVG_QUANTITY - AVG_QUANTITY_LAS_YEAR) / AVG_QUANTITY_LAS_YEAR) *
          100;

        let ytdactual = QUANTITY;

        var today = new Date();
        let numberDayOffPass = 0;
        var firstday = new Date(`${this.year}-1-1`);
        var timeinmilisec = today.getTime() - firstday.getTime();
        this.numberDayUntillNow = Math.ceil(
          timeinmilisec / (1000 * 60 * 60 * 24)
        );
        this.RemaningDays = 365 - this.numberDayUntillNow;
    
        this.AnnualDaysOff.forEach((el) => {
          if (this.isInThePast(new Date(el.day))) {
            // console.log(el)
            numberDayOffPass = numberDayOffPass + 1;
          } else {
            this.RemaningDaysOff = this.RemaningDaysOff + 1;
          }
        });

        this.annualremanining = this.annualPlan - ytdactual;

        let remaningavg =
        this.annualPlan / (this.RemaningDays - this.RemaningDaysOff);
      
        let numberDayss = this.numberDayUntillNow - this.numberDaysOff;
        ytdplan = (this.annualPlan / 356) * numberDayss;
        this.itemsYTD.push({
          ytdactual: ytdactual,
          ytdplan: ytdplan,
          annualplan: this.annualPlan,
          annualremanining: `${this.annualremanining} %`,
        });

      
        this.itemsYTDAvg.push({
          ytdActualAvg: AVG_QUANTITY.toPrecision(4),
          ytdGrowth: `${ytdGrowth.toPrecision(4)} %`,
          ytdAchievement: `${ytdAchivement.toPrecision(4)}%`,
          yoyAvgComparison: yoyAvgComparison.toPrecision(4),
          ramaningAvg: remaningavg.toPrecision(4)
        });

        let day = `${this.day}-${this.month}-${this.year}`;
        let lastday = `31-12-${this.year}`;
        let remaningdays = 365 - this.numberDayUntillNow;
        this.itemsDays.push({
          today: day,
          lastday: lastday,
          numberDayUntillNow: parseFloat(this.numberDayUntillNow),
          remaningdays: parseFloat(remaningdays),
        });
      });
    },
    isInThePast(date) {
      const today = new Date();

      // 👇️ OPTIONAL!
      // This line sets the hour of the current date to midnight
      // so the comparison only returns `true` if the passed in date
      // is at least yesterday
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    getPlanByYear() {
      // var today = new Date();
      // var date_to_reply = new Date("2021-01-01");
      // var timeinmilisec = today.getTime() - date_to_reply.getTime();
      // let numberDays = Math.floor(timeinmilisec / (1000 * 60 * 60 * 24));
      let url = `AnnualPlan/GetByYear?year=${this.year}`;

      this.ApiService.get(url).then((res) => {
     

        this.AnnualRegion = res.data.responseData.regions;
        this.AnnualMonths = res.data.responseData.months;
        this.AnnualDaysOff = res.data.responseData.daysOffs;
        this.AnnualProduct = res.data.responseData.products;
        this.annualPlan = res.data.responseData.total;
        this.AnnualActivity = res.data.responseData.activities;

        // annualPlanbyDay = this.annualPlan / numberDays;
        // console.log(annualPlanbyDay);
        this.getTotalSalesPerProductAnnual();
        this.getYTDAvg();
      });
    },

    async getTotalSalesPerProduct() {
      //  let data=[]
      this.data = [];
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: false,
        url: `https://salestools.yamamacement.com/api/getreportService/getTotalSalesPerProduct?fromDate=1/1/${this.year}&toDate=${this.month}/${this.day}/${this.year} `,
      }).then((response) => {
        let OPMBAG = 0;
        let SRCBAG = 0;
        let SRCBULK = 0;
        let OPMBULK = 0;

        let series = response.data.responseData;
        series.forEach((el) => {
          if (el.productNumber.indexOf("OPM6") > -1) {
            OPMBAG += el.quantity;
          } else if (el.productNumber.indexOf("OPM5") > -1) {
            OPMBULK += el.quantity;
          } else {
            SRCBULK += el.quantity;
          }
        });
        this.DataProductActual.push(SRCBULK);
        this.DataProductActual.push(SRCBAG);
        this.DataProductActual.push(OPMBAG);
        this.DataProductActual.push(OPMBULK);

        series.forEach((el) => {
          if (el.productNumber.indexOf("OPM5") > -1) {
            this.dataBAG += el.quantity;
          } else {
            this.dataOPM += el.quantity;
          }
        });

        this.seriesBAGOPM.push(this.dataBAG);
        this.seriesBAGOPM.push(this.dataOPM);
        // this.series2.push({ data });
        // console.log(this.options2.xaxis.categories);
      });
    },
    getMarketSales() {},
    getDate() {
      var date = new Date();
      this.year = date.getFullYear();
      this.month = date.getMonth() + 1;
      this.day = date.getDate();
    },
    getMonths() {
      var date = new Date();
      let monthNum = date.getMonth() + 1;

      let month = [
        `'1/1/${this.year}'`,
        `'2/1/${this.year}'`,
        `'3/1/${this.year}'`,
        `'4/1/${this.year}'`,
        `'5/1/${this.year}'`,
        `'6/1/${this.year}'`,
        `'7/1/${this.year}'`,
        `'8/1/${this.year}'`,
        `'9/1/${this.year}'`,
        `'10/1/${this.year}'`,
        `'11/1/${this.year}'`,
        `'12/1/${this.year}'`,
      ];

      month.forEach((el, index) => {
        if (index < monthNum) {
          const date1 = new Date(el);
          this.getAnnualPlanByMonth(date1);
          this.getActualPlanByMonth(date1);
        }
      });
    },
    getDaysInMonthAnnual(year, month) {
      return new Date(year, month, 0).getDate();
    },
    getDaysInMonth(year, month) {
      var date = new Date();

      let thisMonth = date.getMonth() + 1;

      if (month == thisMonth) {
        // Create a date object for the current instant
        var now = new Date();

        // Make an exact copy
        var startOfMonth = new Date(+now);

        // Set the copy's date to the start of the month
        startOfMonth.setDate(1);

        // Get the difference in time value and calculate whole days
        return Math.ceil((now - startOfMonth) / 8.64e7) + 1;
      } else {
        return new Date(year, month, 0).getDate();
      }
    },
    getthisMonthName(date) {
      let fulldate = date.getMonth();

      this.monthsNameArabic.forEach((el) => {
        if (el.num == fulldate) {
          this.thisMonth = el.name;
        }
      });
    },
    async getAnnualPlanByMonth(date) {
      let NumDaysOff = 0;

      let targetMonth = 0;

      date = new Date(date);
      this.getthisMonthName(date);

      const date1 = new Date(date);
      const currentYear = date1.getFullYear();
      const currentMonth = date1.getMonth() + 1;
      const NumberOfdays = this.getDaysInMonthAnnual(currentYear, currentMonth);

      this.AnnualMonths.forEach((el) => {
        if (el.month1 == this.thisMonth) {
          targetMonth = el.target;
        }
      });
      this.AnnualDaysOff.forEach((el) => {
        if (this.sameDay(el.day, date)) {
          NumDaysOff++;
        }
      });

      let NumDays = NumberOfdays - NumDaysOff;

      let ActualMonth = targetMonth / NumDays;

      // let planAvg = ActualMonth / NumDays;
      
      let planAvg = ActualMonth ;

      this.PlanAvg.push({ planavg: planAvg });
    },
    async getActualPlanByMonth(date) {
      let NumDaysOff = 0;

      let targetMonth = 0;

      date = new Date(date);
      this.getthisMonthName(date);

      const date1 = new Date(date);
      const currentYear = date1.getFullYear();
      const currentMonth = date1.getMonth() + 1;
      const NumberOfdays = this.getDaysInMonth(currentYear, currentMonth);

      this.QuantityMonths.forEach((el) => {
        if (el.name == this.thisMonth) {
          targetMonth = el.total;
        }
      });
      this.AnnualDaysOff.forEach((el) => {
        if (this.sameDay(el.day, date)) {
          NumDaysOff++;
        }
      });

      let NumDays = NumberOfdays - NumDaysOff;

      this.annualMonth = targetMonth / NumDays;

      let actualavg = this.annualMonth ;

      this.ActualAvg.push({ actualavg: actualavg });
    },
    sameDay(d1, d2) {
      const firstMonth = new Date(d1);
      const secondMonth = new Date(d2);

      return (
        firstMonth.getFullYear() === secondMonth.getFullYear() &&
        firstMonth.getMonth() === secondMonth.getMonth()
      );
    },

    getRemaningDays() {
      let lastDate = `${this.year}-12-31`;
      let RemaningDaysToFinishYear = this.dateDifference(
        new Date(),
        new Date(lastDate)
      );
      this.RemaningDaysToFinishYear =
        RemaningDaysToFinishYear - this.numberDaysOff;
    },
    dateDifference(date2, date1) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;

      // Discard the time and time-zone information.
      const utc1 = Date.UTC(
        date1.getFullYear(),
        date1.getMonth(),
        date1.getDate()
      );
      const utc2 = Date.UTC(
        date2.getFullYear(),
        date2.getMonth(),
        date2.getDate()
      );

      return Math.floor((utc1 - utc2) / _MS_PER_DAY);
    },
  },
};
</script>

<style scoped>
.my-header-style {
  color: #1a6650 !important;
  background-color: #e1ecdb !important;
}

.avg-style {
  color: #000 !important;
  background-color: #fff1ca !important;
}

.v-btn {
  right: 600px;
}
</style>
